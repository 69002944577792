<template>
    <div>

        <!--图片预览区-->
        <b-container class="bv-example-row" :style="{border: dragAreaBorder,minHeight: '200px'}"
                     v-on:dragover="fileDragOver" v-on:dragleave="fileDragLeave" v-on:drop="fileDrop">
            <b-alert show dismissible fade variant="danger" class="text-center" v-if="message!=null">{{ message }}
            </b-alert>
            <b-row style="min-height: 200px;" v-if="previews_.length<1">
                <b-col class="text-center" align-self="center">
                    <h3 style="color: #78909c !important">将文件拖拽到此处，或点击选择文件</h3>
                    <h4 class="mt-3" style="color: #78909c !important"> 单个文件大小限制 {{ this.maxFileSize }} KB</h4>
                    <h4 class="mt-3" style="color: #78909c !important">最多可上传 {{ this.maxFileCount }} 个文件</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col :lg="multiple?4:6" :md="multiple?4:6" :sm="multiple?4:6" v-for="(preview,index) in previews_">
                    <b-card footer-tag="footer" bodyClass="image-wrapper">
                        <div class="clearfix text-center">
                            <v-badge color="success" icon="mdi-cloud-check" overlap v-if="preview.uploaded">
                                <b-img center height="180" :src="preview.src" alt="Left image"
                                       class="mx-2 my-2 img-cover"></b-img>
                            </v-badge>
                            <b-img center height="180" :src="preview.src" alt="Left image" class="mx-2 my-2 img-cover"
                                   v-else></b-img>
                        </div>
                        <b-alert center show dismissible fade variant="danger" class="text-center"
                                 v-if="preview.message!=null">
                            {{ preview.message }}
                        </b-alert>
                        <template v-slot:footer>
                            <v-btn small text icon fab v-on:click="onZoomIn(preview)">
                                <v-icon>flaticon-eye</v-icon>
                            </v-btn>
                            <v-btn small text icon fab v-on:click="onDelete(preview)">
                                <v-icon>la la-trash</v-icon>
                            </v-btn>
                        </template>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>

        <!--图片放大modal-->
        <b-modal ref="zoom-modal" centered hide-footer>
            <b-img center :src="zoomImage" alt="Left image" class="mx-2 my-2 img-cover"></b-img>
        </b-modal>

        <!--上传进度条-->
        <b-progress class="mt-3" max="100" :variant="progress.variant" v-if="progress.show">
            <b-progress-bar :value="progress.value" :label="progress.label"></b-progress-bar>
        </b-progress>

        <!--文件选择区-->
        <b-form-group class="mb-0">
            <b-input-group class="mt-3">
                <b-form-file ref="file_input" :multiple="multiple" :accept="accept" placeholder="请选择文件... "
                             :file-name-formatter="formatFileName" v-model="files"/>
                <b-input-group-append>
                    <b-button variant="outline-danger" v-on:click="onRemove">
                        <b-icon icon="trash"></b-icon>
                        移除
                    </b-button>
                    <b-button variant="outline-success" v-on:click="onUpload" :disabled="completed">
                        <b-icon icon="arrow-up"></b-icon>
                        上传
                    </b-button>
                    <b-button variant="info" v-on:click="onChoose">
                        <b-icon icon="plus"></b-icon>
                        选择
                    </b-button>
                </b-input-group-append>
            </b-input-group>
        </b-form-group>
    </div>
</template>

<script>
    import Vue from "vue";
    import md5 from "js-md5";

    export default {
        name: "FileUpload",
        props: {
            multiple: {
                type: Boolean,
                default: true
            },
            autoUpload: {
                type: Boolean,
                default: true
            },
            accept: {
                type: String,
                default: "image/jpeg, image/png, image/gif, image/jpg"
            },
            maxFileCount: {
                type: Number,
                default: 1
            },
            maxFileSize: {
                type: Number,
                default: 2048
            },
            uploadUrl: {
                type: String,
                default: "/api/images/multi_upload"
            },
            result: {
                type: Object
            },
            uploaded: []
        },

        data() {
            return {
                valid: true,
                message: null,
                zoomImage: "",
                files: [],
                timestamp: "",
                previews: {},
                previews_: [],
                progress: {
                    value: 0,
                    label: "完成",
                    show: false,
                    variant: "success"
                },
                completed: false,
                dragAreaBorder: "2px dotted #cccccc"
            };
        },

        created() {
            this.timestamp = new Date().getTime();
        },

        methods: {
            fileDragOver(event) {
                event.stopPropagation();
                event.preventDefault();
                this.dragAreaBorder = "3px dotted #3699ff";

            },
            fileDragLeave(event) {
                event.stopPropagation();
                event.preventDefault();
                this.dragAreaBorder = "2px dotted #cccccc";
            },
            fileDrop(event) {
                event.stopPropagation();
                event.preventDefault();
                this.dragAreaBorder = "2px dotted #cccccc";
                const files = event.dataTransfer.files // 获取上传的文件对象
                this.onInputFile(files);
            },
            onInputFile(files) {
                this.checkFileCount(files);
                this.progress.show = false;

                if (!this.multiple) {
                    this.previews = {};
                } else {
                    for (let fileId in this.previews) {
                        if (!this.previews[fileId].uploaded) {
                            Vue.delete(this.previews, fileId);
                        }
                    }
                }

                let that = this;
                let fileLength = files.length;
                let loadedCount = 0;
                for (let i = 0; i < fileLength; i++) {
                    let file = files[i]
                    let check = that.checkFile(file);

                    let reader = new FileReader();   //使用h5的读取文件api
                    reader.readAsDataURL(file);
                    reader.onload = function () {    //读取完成后触发
                        let fileId = md5(file.name + "_" + that.timestamp);
                        let item = {
                            fileId: fileId,
                            file: file,
                            src: this.result,
                            uploaded: false,
                            message: check.message
                        };
                        Vue.set(that.previews, fileId, item);
                       console.info("loaded    :",that.autoUpload);

                        if (that.autoUpload) {
                            console.info("that:",that.autoUpload);
                            loadedCount += 1;
                            if (loadedCount >= fileLength) {
                                that.onUpload();
                            }
                        }
                    };
                }
            },

            onRemove() {
                this.$refs['file_input'].reset();
            },
            onChoose() {
                this.$refs['file_input'].$el.firstChild.click();
            },
            onZoomIn(file) {
                this.zoomImage = file.src;
                this.$refs['zoom-modal'].show();
            },
            onDelete(file) {
                Vue.delete(this.previews, file.fileId);
                if (!file.uploaded) {
                    if (Array.isArray(this.files)) {
                        for (let i = 0; i < this.files.length; i++) {
                            if (this.files[i] === file.file) {
                                this.files.splice(i, 1);   // 将使后面的元素依次前移，数组长度减1
                                i--;                                  // 如果不减，将漏掉一个元素
                            }
                        }
                    } else {
                        this.files = [];
                    }
                }
            },
            onUpload() {
                let empty = true;

                let formData = new FormData()
                for (let fileId in this.previews) {
                    let preview = this.previews[fileId];
                    if (!preview.uploaded) {
                        formData.append('files', preview.file);
                        empty = false;
                    }
                }

                formData.append("timestamp", this.timestamp);

                if (!this.valid) {
                    this.message = "请先移除不符合上传要求的文件！";
                    return;
                }

                if (empty) {
                    return;
                }

                this.progress.show = true;
                this.progress.variant = 'success';

                let headers = {
                    'Content-Type': 'multipart/form-data'
                }

                let that = this;
                let process = function (progressEvent) {
                    let complete = (progressEvent.loaded / progressEvent.total * 100 | 0);
                    if (complete == 100) {
                        that.progress.label = "99%";
                        that.progress.value = 99;
                    } else {
                        that.progress.label = complete + '%';
                        that.progress.value = complete;
                    }
                }

                Vue.axios.post(this.uploadUrl, formData, {
                    headers: headers,
                    onUploadProgress: process
                }).then(({data}) => {
                    if (data.success) {
                        that.progress.variant = 'success';
                        that.progress.label = '完成';
                        that.progress.value = 100;
                        this.uploadedEvent(data);
                    } else {
                        that.progress.variant = 'danger';
                        that.progress.label = '上传失败:' + data.message;
                        this.uploadFailEvent(data);
                    }
                }).catch(({response}) => {
                    that.progress.variant = 'danger';
                    that.progress.label = '上传失败:' + response;
                    this.uploadFailEvent(response);
                });
            },

            formatFileName(files) {
                return files.length === 1 ? files[0].name : `${files.length} 个文件已选中`
            },

            checkFileCount(files) {
                this.valid = true;
                this.message = null;
                let maxFileCount = this.multiple ? this.maxFileCount : 1;
                if (files.length > maxFileCount) {
                    this.valid = false;
                    this.message = "已选文件数量 " + files.length + "，大于当前最大限制数量 " + maxFileCount;
                    this.fileInputEvent();
                    return;
                }
            },

            checkFile(file) {
                let kbSize = Math.round(file.size / 1024 * 100) / 100;
                if (this.accept.indexOf(file.type) < 0) {
                    this.valid = false;
                    return {message: "当前文件格式为[" + file.type + "] 不符合上传要求!"};
                }
                if (kbSize > this.maxFileSize) {
                    this.valid = false;
                    return {message: "该文件大小为 " + kbSize + "kb,大于最大上传限制" + this.maxFileSize + "kb"};
                }
                return {message: null};
            },

            uploadedEvent(data) {
                this.result = data;
                let uploaded = data.result;
                let that = this;
                if (Array.isArray(uploaded)) {
                    uploaded.forEach(function (upload) {
                        that.previews[upload.fileId].uploaded = true;
                        that.previews[upload.fileId].imageUrl = upload.imageUrl;
                    });
                }
                this.completed = true;
                this.$emit('uploaded', this.previews);
            },
            uploadFailEvent(data) {
                this.result = data;
                this.$emit('uploadFail', '');
            },
            fileInputEvent() {
                this.$emit('change', '');
            },
            setUploaded(uploaded) {
                let that = this;
                if (Array.isArray(uploaded)) {
                    uploaded.forEach(function (upload) {
                        Vue.set(that.previews, upload.fileId, upload);
                    });
                }
            }
        },
        computed: {},

        watch: {
            files: {
                handler(newValue, oldValue) {
                    let files = Array.isArray(newValue) ? newValue : newValue == null ? [] : [newValue];
                    this.onInputFile(files);
                },
                deep: true
            },
            previews: {
                handler(newValue, oldValue) {
                    let uploaded = [];
                    this.previews_ = [];
                    for (let fileId in newValue) {
                        let preview = this.previews[fileId];
                        this.previews_.push(preview);
                        if (preview.uploaded) {
                            uploaded.push({fileId: preview.fileId, imageUrl: preview.imageUrl});
                        }
                    }
                    this.$emit('update:uploaded', uploaded);
                },
                deep: true,
                immediate: true,
            },
        },
    };
</script>

<style>

    .custom-file-label::after {
        content: '' !important;
        padding: 0 !important;
    }

    .img-cover {
        object-fit: cover !important;
        width: 100% !important;
    }

    .image-wrapper {
        padding: 1.25rem !important
    }

</style>