<template>
  <div>
    <div class="px-10 py-0">
      <!--begin: Wizard-->
      <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps p-5 p-lg-10">
            <div class="wizard-step" data-wizard-type="step" data-wizard-state="current">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-list"></i>
                <h3 class="wizard-title">1. 提现信息</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <i class="wizard-icon flaticon-eye"></i>
                <h3 class="wizard-title">2. 提现确认</h3>
              </div>
              <i class="wizard-arrow flaticon2-next"></i>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <div class="separator separator-dashed mb-5"></div>

        <!--begin: Wizard Body-->
        <div class="row justify-content-center">
          <div class="col-sm-10 col-md-6 col-xl-5 col-xxl-5">
            <!--begin: Wizard Form-->
            <form class="form" id="product_edit_form">
              <!--begin: 提现信息-->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                <b-alert v-if="account.status==0" class="mt-2" show variant="danger" dismissible>提现功能被禁用，请联系商务处理!
                </b-alert>
                <b-alert v-if="account.status==2" class="mt-2" show variant="danger" dismissible>
                  未绑定谷歌身份验证，请先在完成个人中心绑定后，才可提现! </b-alert>
                <b-alert v-if="showTips" class="mt-2" show variant="danger" dismissible>余额不足! </b-alert>

                <b-row>
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">账户余额:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input readonly disabled type="text" v-model="account.balance">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">提现金额:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="amount" type="number" v-model="formModel.amount" placeholder="提现金额...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">结算银行卡:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-select @change="selectCard" name="bankCard" type="text" v-model="formModel.bankCardId"
                        placeholder="提现银行卡...">
                        <option value="">请选择银行卡..</option>
                        <option :key="item.id" :value="item.id" v-for="item in bankcards">{{ item.accountName }}
                          ({{ item.bankName }})</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">银行卡卡号:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="cardNumber" type="text" v-model="formModel.cardNumber" placeholder="银行卡卡号...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">银行编码:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="bankCode" type="text" v-model="formModel.bankCode" placeholder="银行编码...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">持卡人姓名:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="accountName" type="text" v-model="formModel.accountName"
                        placeholder="持卡人姓名...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">开户行名称:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="bankName" type="text" v-model="formModel.bankName" placeholder="开户行名称...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">开户支行:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="bankSubbranch" type="text" v-model="formModel.bankSubbranch"
                        placeholder="开户支行名称...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">收款人电话:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="payeePhone" type="text" v-model="formModel.payeePhone" placeholder="收款人电话...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">收款人邮箱:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-input name="payeeEmail" type="text" v-model="formModel.payeeEmail" placeholder="收款人邮箱...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <!--end: 提现信息-->

              <!--begin: 提现确认-->
              <div id="confirm_step" class="pb-5" data-wizard-type="step-content">

                <b-alert class="mt-2" show variant="light" dismissible>请确认提现信息，再输入支付密码提交申请。</b-alert>


                <b-row class="m-0 mt-2 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">账户余额</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="m-0 comfirm-content">
                    {{ account.balance }}
                  </b-col>
                </b-row>


                <b-row class="m-0 mt-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">提现金额</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="m-0 comfirm-content">
                    {{ formModel.amount ? formModel.amount : "--" }}
                  </b-col>
                </b-row>

                <b-row class="m-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">银行卡卡号</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="comfirm-content">
                    {{ formModel.cardNumber ? formModel.cardNumber : "--" }}
                  </b-col>
                </b-row>

                <b-row class="m-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">银行编码</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="comfirm-content">
                    {{ formModel.bankCode ? formModel.bankCode : "--" }}
                  </b-col>
                </b-row>


                <b-row class="m-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">持卡人姓名</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="comfirm-content">
                    {{ formModel.accountName ? formModel.accountName : "--" }}
                  </b-col>
                </b-row>

                <b-row class="m-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">开户行名称</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="comfirm-content">
                    {{ formModel.bankName ? formModel.bankName : "--" }}
                  </b-col>
                </b-row>

                <b-row class="m-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">开户支行</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="comfirm-content">
                    {{ formModel.bankSubbranch ? formModel.bankSubbranch : "--" }}
                  </b-col>
                </b-row>

                <b-row class="m-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">收款人电话</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="comfirm-content">
                    {{ formModel.payeePhone ? formModel.payeePhone : "--" }}
                  </b-col>
                </b-row>

                <b-row class="m-0 p-0">
                  <b-col cols="3" class="m-0 p-0 text-left comfirm-label">
                    <label class="ml-2 form-group-label">收款人邮箱</label>
                  </b-col>
                  <b-col cols="9" align-self="center" class="comfirm-content">
                    {{ formModel.payeePhone ? formModel.payeePhone : "--" }}
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col align-self="center">
                    <b-form-group label="谷歌验证码：">
                      <b-form-input name="googleCode" type="password" v-model="formModel.googleCode"
                        placeholder="谷歌验证码：...">
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
              <!--end: 提现确认-->

              <!--begin: 按钮组 -->
              <div class="d-flex justify-content-between border-top pt-10 mb-8">
                <div class="mr-2">
                  <button class="
                      btn btn-light-primary
                      font-weight-bold
                      text-uppercase
                      px-5
                      py-2
                    " data-wizard-type="action-prev">
                    <v-icon>flaticon2-left-arrow</v-icon>
                    上一步
                  </button>
                </div>

                <div>
                  <button v-on:click="submit" class="
                      btn btn-success
                      font-weight-bold
                      text-uppercase
                      px-5
                      py-2
                    " data-wizard-type="action-submit">
                    <v-icon>flaticon2-fax</v-icon>
                    提交
                  </button>
                  <button :disabled="account.status!=1" class="
                      btn btn-light-primary
                      font-weight-bold
                      text-uppercase
                      px-5
                      py-2
                    " data-wizard-type="action-next">
                    <v-icon>flaticon2-right-arrow</v-icon>
                    下一步
                  </button>
                </div>
              </div>
              <!--end: 按钮组 -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/wizard/wizard-1.scss";

  .comfirm-label {
    border: 1px solid #d8dce1;
    border-collapse: collapse;
    border-spacing: 0;
    background: #f8fafc;
  }

  .comfirm-content {
    border: 1px solid #d8dce1;
    border-spacing: 0;
    border-left: none;
    border-collapse: collapse;
  }
</style>

<script>
  import KTUtil from "@/assets/js/components/util";
  import KTWizard from "@/assets/js/components/wizard";
  import FileUpload from "@/view/pages/oms_pages/plugin/FileUpload";
  import ApiService from "@/core/services/api.service";
  // FormValidation plugins
  import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
  import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
  import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
  import Swal from "sweetalert2";

  export default {
    name: "Edit",
    components: {
      FileUpload,
    },
    data() {
      return {
        wizardIndex: 1,
        bankcards: [],
        account: {},
        showTips: false,
        formModel: {
          payeeEmail: " ",
          type:2
        },
      };
    },

    created() {
      this.getBankcards();
      this.getAccount();
    },

    mounted() {
      // Initialize form wizard
      const wizard = new KTWizard("kt_wizard_v1", {
        startStep: this.wizardIndex, // initial active step number
        clickableSteps: true, // allow step clicking
      });

      let that = this;
      // Validation before going to next page
      wizard.on("beforeNext", function ( /*wizardObj*/ ) {
        // validate the form and use below function to stop the wizard's step
      });

      // Change event
      wizard.on("change", function (wizardObj) {
        let step = wizard.getStep();
        wizard.stop();
        if (step == 1) {
          that.fv.validate().then(function (status) {
            if (status == "Valid") {
              let amount = parseFloat(that.formModel.amount);
              console.info(amount);
              if (amount > that.account.balance) {
                that.showTips = true;
                return;
              } else {
                that.showTips = false;
              }
              that.fv.addField('googleCode', {
                validators: {
                  notEmpty: {
                    message: "请输入谷歌验证码!",
                  },
                },
              });
              wizard.goNext();
            }
          });
        } else if (step == 2) {
          that.fv.resetField('googleCode', false);
          that.fv.removeField('googleCode');
          wizard.goPrev();
        }
        setTimeout(() => {
          KTUtil.scrollTop();
        }, 500);
      });

      // Create a FormValidation instance
      let editForm = KTUtil.getById("product_edit_form");
      this.fv = formValidation(editForm, {
        fields: {
          bankCode: {
            validators: {
              notEmpty: {
                message: "银行编码不能为空!",
              },
            },
          },
          bankName: {
            validators: {
              notEmpty: {
                message: "银行名称不能为空!",
              },
            },
          },
          accountName: {
            validators: {
              notEmpty: {
                message: "持卡人姓名不能为空!",
              },
            },
          },
          cardNumber: {
            validators: {
              notEmpty: {
                message: "持卡人姓名不能为空!",
              },
            },
          },
          payeePhone: {
            validators: {
              notEmpty: {
                message: "收款人电话不能为空!",
              },
            },
          },
          payeeEmail: {
            validators: {
              notEmpty: {
                message: "收款人邮箱不能为空!",
              },
            },
          },
          amount: {
            validators: {
              notEmpty: {
                message: "提现金额不能为空!",
              },
            },
          },

        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap(),
        },
      });
    },

    methods: {
      getBankcards() {
        this.bankcards = [];
        let that = this;
        let params = {
          noPaging: true,
          status: 1
        };

        ApiService.query("/api/merchant/bankcard/query_list", {
          params: params,
        }).then(({
          data
        }) => {
          that.bankcards = data.result.data;
        });
      },

      getAccount() {
        let that = this;
        ApiService.query("/api/merchant/account/query_by_id", {

        }).then(({
          data
        }) => {
          that.account = data.result;
          that.account.balance = data.result.balance / 100.0
        });
      },

      selectCard() {
        if (this.bankcards != null && this.bankcards != undefined) {
          let bankCardId = this.formModel.bankCardId;
          let bankcard = this.bankcards.find((card) => card.id == bankCardId);
          if (bankcard != null && bankcard != undefined) {
            this.formModel.bankCode = bankcard.bankCode;
            this.formModel.bankName = bankcard.bankName;
            this.formModel.accountName = bankcard.accountName;
            this.formModel.cardNumber = bankcard.cardNumber;
            this.formModel.bankSubbranch = bankcard.bankSubbranch;
            this.formModel.payeePhone = bankcard.payeePhone;
            this.formModel.payeeEmail = bankcard.payeeEmail;
          }

        }
      },


      submit() {
        let that = this;
        this.fv.validate().then(function (status) {
          if (status === "Valid") {
            ApiService.post("/api/merchant/withdraw/apply", that.formModel).then(({
              data
            }) => {
              if (data.success) {
                that.$router.push("/merch/withdraw/record");
              } else {
                Swal.fire({
                  title: "提交失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            });
          }
        });
      },


      goBack() {
        this.$router.push("/merch/withdraw/record");
      },
    },
    computed: {

    },

    watch: {

    },
  };
</script>