<template>
  <div>
    <KTCard>
      <template v-slot:body>
        <div style="min-height: 90vh">
          <b-tabs id="withdraw_tabs" v-model="tabIndex">
            <b-tab active>
              <template v-slot:title>
                <i class="fab fa-amazon-pay"></i> <span class="ml-2">银行卡提现</span>
              </template>
              <div class="p-3">
                <BankCardApply></BankCardApply>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <i class="flaticon-coins"></i> <span class="ml-2">USDT提现</span>
              </template>
              <div class="p-3">
                <USDTApply></USDTApply>
              </div>
            </b-tab>

            <b-tab>
              <template v-slot:title>
                <i class="far fa-credit-card"></i> <span class="ml-2">银行卡管理</span>
              </template>
              <div class="p-3">
                <Bankcard></Bankcard>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import KTCard from "@/view/content/Card.vue";
import BankCardApply from "@/view/pages/merch_pages/fund/compenent/Withdraw_BankCard.vue";
import USDTApply from "@/view/pages/merch_pages/fund/compenent/Withdraw_USDT.vue";

import Bankcard from "@/view/pages/merch_pages/fund/compenent/BankCard_Manage.vue";


import Swal from "sweetalert2";

export default {
  name: "Edit",
  components: {
    KTCard,
    BankCardApply,
    USDTApply,
    Bankcard,
  },
  data() {
    return {
      tabIndex: 0
    };
  },

  created() {
    this.tabIndex = 0;
  },

  mounted() {
    this.tabIndex = 0;
  },

  methods: {
    goBack() {
      this.$router.push("/withdraw/record");
    },
  },
  computed: {

  },

  watch: {

  },
};
</script>