<template>
  <div>
    <div class="px-2 mt-1" style="min-height: 70vh">
      <!--表格-->
      <v-data-table show-select fixed-header dense height="70vh" v-model="table.selected" :headers="table.headers"
        :items="table.desserts" :single-select="table.singleSelect" :footer-props="table.footer"
        :server-items-length="table.itemsCount" :options.sync="table.pagination" :no-data-text="table.noDataText"
        :loading="table.loading" :loading-text="table.loadingText" item-key="id">
        <template v-slot:item.type="{ item }">
          <span v-if="item.type == 1">公司账户 </span>
          <span v-else-if="item.type == 2">个人账户 </span>
        </template>

        <template v-slot:item.updateTime="{ item }">
          {{item.updateTime|formatDate}}
        </template>

        <template v-slot:item.status="{ item }">
          <h6>
            <b-badge variant="success" v-if="item.status == 1">正常 </b-badge>
            <b-badge variant="primary" v-else-if="item.status == 2">待审核
            </b-badge>
            <b-badge variant="danger" v-else>禁用</b-badge>
          </h6>
        </template>

        <!--状态字段   格式处理-->
        <template v-slot:item.action="{ item }">
          <v-btn small text icon fab v-b-tooltip.hover.lefttop title="编辑" v-on:click="edit(item.id)">
            <v-icon>la la-edit</v-icon>
          </v-btn>
        </template>

        <!--表格筛选-->
        <template v-slot:top>
          <b-row class="example-tools my-2" align-h="end">
            <!--按钮组-->
            <b-button variant="primary" @click="create()" class="mx-2">
              <b-icon icon="plus"></b-icon>
              新增
            </b-button>

            <!--按钮组-->
            <b-button variant="success" @click="edit()" class="mx-2">
              <b-icon icon="pencil"></b-icon>
              编辑
            </b-button>
            <!--按钮组-->
          </b-row>

          <!--分隔线-->
          <v-divider></v-divider>
        </template>
      </v-data-table>
    </div>

    <!--模态框-数据表单-->
    <b-modal ref="my-modal" size="lg" centered title="商户提现账号编辑" static="true">
      <b-container fluid>
        <b-form id="merchantBankcardForm" method="POST" class="form" novalidate="novalidate">
          <b-row>
            <b-col cols="6">
              <b-form-group label="银行卡类型">
                <b-form-select name="type" v-model="formModel.type" placeholder="请输入银行卡类型">
                  <option value="1">公司账户</option>
                  <option value="2">个人账户</option>
                </b-form-select>
              </b-form-group>
              <b-form-group label="持卡人姓名:">
                <b-form-input type="text" name="accountName" v-model="formModel.accountName" placeholder="持卡人姓名...">
                </b-form-input>
              </b-form-group>
              <b-form-group label="银行名称:">
                <b-form-input type="text" name="bankName" v-model="formModel.bankName" placeholder="请输入银行名称...">
                </b-form-input>
              </b-form-group>

              <b-form-group label="收款人电话:">
                <b-form-input type="text" name="payeePhone" v-model="formModel.payeePhone" placeholder="请输入收款人电话...">
                </b-form-input>
              </b-form-group>
              <b-form-group label="备注:">
                <b-form-input type="text" name="remark" v-model="formModel.remark" placeholder="请输入备注...">
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="银行代号:">
                <b-form-input type="text" name="bankCode" v-model="formModel.bankCode" placeholder="请输入银行代号...">
                </b-form-input>
              </b-form-group>
              <b-form-group label="银行卡账号:">
                <b-form-input type="text" name="cardNumber" v-model="formModel.cardNumber" placeholder="请输入银行卡账号...">
                </b-form-input>
              </b-form-group>

              <b-form-group label="开户支行:">
                <b-form-input type="text" name="bankSubbranch" v-model="formModel.bankSubbranch"
                  placeholder="请输入开户支行..."></b-form-input>
              </b-form-group>
              <b-form-group label="收款人邮箱:">
                <b-form-input type="text" name="payeeEmail" v-model="formModel.payeeEmail" placeholder="请输入收款人邮箱...">
                </b-form-input>
              </b-form-group>

              <b-form-group label="状态:">
                <v-radio-group class="mt-0" row name="status" v-model="formModel.status" :mandatory="false">
                  <v-radio label="正常" :value="1"></v-radio>
                  <v-radio label="禁用" :value="0"></v-radio>
                </v-radio-group>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      <!--表格筛选-->
      <template v-slot:modal-footer>
        <b-button v-on:click="onClose" variant="danger">取消</b-button>
        <b-button v-on:click="onSubmit" variant="primary">保存</b-button>
      </template>
    </b-modal>
    <!--模态框-数据表单-->
  </div>
</template>

<script>
  import Vue from "vue";

  import KTCard from "@/view/content/Card.vue";
  import KTUtil from "@/assets/js/components/util";

  import ApiService from "@/core/services/api.service";

  // FormValidation plugins
  import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
  import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
  import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
  import Swal from "sweetalert2";

  export default {
    name: "List",
    components: {
      KTCard,
    },
    props: {
      title: String,
    },
    data() {
      return {
        filter: {
          conditions: [{
              value: null,
              text: "检索条件"
            },
            {
              value: "supplierName",
              text: "供货商名称"
            },
            {
              value: "loginAccount",
              text: "登录账号"
            },
            {
              value: "contactPhone",
              text: "联系电话"
            },
            {
              value: "email",
              text: "电子邮箱"
            },
          ],
          statusOptions: [{
              value: null,
              text: "状态"
            },
            {
              value: 0,
              text: "禁用"
            },
            {
              value: 2,
              text: "待审核"
            },
            {
              value: 1,
              text: "正常"
            },
          ],
          field: null,
          keyword: "",
          status: null,
        },
        submitUrl: "",
        formModel: {
          id: "",
        },
        table: {
          loadingText: "正在加载...",
          noDataText: "暂无数据...",
          loading: false,
          singleSelect: false,
          selected: [],
          itemsCount: 10,
          headers: [{
              text: "银行卡类型",
              value: "type"
            },
            {
              text: "持卡人姓名",
              value: "accountName"
            },
            {
              text: "银行卡账号",
              value: "cardNumber"
            },
            {
              text: "银行代号",
              value: "bankCode"
            },
            {
              text: "银行名称",
              value: "bankName"
            },
            {
              text: "开户支行",
              value: "bankSubbranch"
            },
            {
              text: "收款人电话",
              value: "payeePhone"
            },
            {
              text: "收款人邮箱",
              value: "payeeEmail"
            },
            {
              text: "备注",
              value: "remark"
            },
            {
              text: "状态",
              value: "status"
            },
            {
              text: "更新时间",
              value: "updateTime"
            },
            {
              text: "操作",
              value: "action"
            },
          ],
          footer: {
            itemsPerPageOptions: [5, 10, 20, 50, 100],
            itemsPerPageText: "分页数量",
            showCurrentPage: true,
          },
          pagination: {},
          desserts: [],
        },
      };
    },
    mounted() {
      // Create a FormValidation instance
      let merchantBankcardForm = KTUtil.getById("merchantBankcardForm");
      this.fv = formValidation(merchantBankcardForm, {
        fields: {
          type: {
            validators: {
              notEmpty: {
                message: "请选择银行卡类型",
              },
            },
          },
          accountName: {
            validators: {
              notEmpty: {
                message: "收款户名不能为空!",
              },
            },
          },
          cardNumber: {
            validators: {
              notEmpty: {
                message: "银行卡账号不能为空!",
              },
            },
          },
          bankCode: {
            validators: {
              notEmpty: {
                message: "银行代号不能为空!",
              },
            },
          },
          bankName: {
            validators: {
              notEmpty: {
                message: "银行名称不能为空!",
              },
            },
          },
          payeePhone: {
            validators: {
              notEmpty: {
                message: "收款人电话不能为空!",
              },
            },
          },
          payeeEmail: {
            validators: {
              notEmpty: {
                message: "收款人邮箱不能为空!",
              },
            },
          },
          status: {
            validators: {
              notEmpty: {
                message: "状态:1审核、0未审不能为空!",
              },
            },
          },
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap(),
        },
      });

      let showModal = this.$route.query.showModal;
      if (showModal == "true") {
        this.create();
      }
    },
    created() {},

    methods: {
      create() {
        this.submitUrl = "/api/merchant/bankcard/create";
        this.formModel = {};
        this.$refs["my-modal"].show();
      },
      edit(id = undefined) {
        this.submitUrl = "/api/merchant/bankcard/update";
        id = id == undefined ? this.getSelectedIds() : [id];
        if (id.length != 1) {
          Swal.fire({
            title: "",
            text: "请选择要编辑的数据行,一次仅且只能选择一条!",
            icon: "warning",
            confirmButtonClass: "btn btn-success",
            heightAuto: false,
          });
          return;
        }

        let that = this;
        ApiService.query("/api/merchant/bankcard/query_by_id", {
          params: {
            id: id[0],
          },
        }).then(({
          data
        }) => {
          that.formModel = data.result;
        });

        this.$refs["my-modal"].show();
      },

      getData() {
        let params = {
          pageNum: this.table.pagination.page,
          pageSize: this.table.pagination.itemsPerPage,
          status: this.filter.status,
        };
        params["" + this.filter.field + ""] = this.filter.keyword;

        ApiService.query("/api/merchant/bankcard/query_list", {
          params: params,
        }).then(({
          data
        }) => {
          this.table.desserts = data.result.data;
          this.table.itemsCount = data.result.total;
        });
      },

      getSelectedIds() {
        let ids = [];
        if (this.table.selected != null && this.table.selected != undefined) {
          this.table.selected.forEach(function (item) {
            ids.push(item.id);
          });
        }
        return ids;
      },

      onClose() {
        this.$refs["my-modal"].hide();
      },

      onSubmit() {
        let that = this;
        this.fv.validate().then(function (status) {
          if (status === "Valid") {
            ApiService.post(that.submitUrl, that.formModel).then(({
              data
            }) => {
              if (data.success) {
                Swal.fire({
                  title: "",
                  text: "保存成功！",
                  icon: "success",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 1500,
                });
                that.getData();
                that.$refs["my-modal"].hide();
              } else {
                Swal.fire({
                  title: "保存失败！",
                  text: data.message,
                  icon: "warning",
                  confirmButtonClass: "btn btn-success",
                  heightAuto: false,
                  timer: 3000,
                });
              }
            });
          }
        });
      },
    },
    computed: {
      pagination: function () {
        return this.table.pagination;
      },
    },
    watch: {
      pagination: {
        handler() {
          this.getData();
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss">
  .custom-v-dropdown {
    &.dropdown-toggle {
      padding: 0;

      &:hover {
        text-decoration: none;
      }

      &.dropdown-toggle-no-caret {
        &:after {
          content: none;
        }
      }
    }

    &.dropdown-menu {
      margin: 0;
      padding: 0;
      outline: none;

      .b-dropdown-text {
        padding: 0;
      }
    }
  }
</style>